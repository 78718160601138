<template>
  <v-app class="bg-login" id="auth">
    <v-main>
      <v-container class="login-container" fluid>
        <v-row align-content="center" class="login-container__row">
          <v-col
            class="login-container__col left hidden-sm-and-down"
            cols="12"
            md="6"
          >
            <div class="left__text">
              <p class="uppercase">Conocimiento</p>
              <p class="uppercase">Y Confiabilidad</p>
            </div>
          </v-col>
          <!-- FORM LOGIN -->
          <v-col class="login-container__col right" cols="12" md="6">
            <v-row>
              <v-col cols="8" offset="2">
                <div
                  class="logo-container d-flex flex-column justify-center align-center"
                >
                  <img
                    src="../../assets/AV_002_logo.jpg"
                    alt="Logo Av"
                    class="logo-container__image"
                  />
                  <p
                    class="logo-container__text"
                    v-if="this.correoSend === false"
                  >
                    Ingrese su dirección de correo electrónico principal, para
                    recuperar su contraseña de acceso a la plataforma.
                  </p>
                </div>
              </v-col>
              <v-col cols="10" offset="1">
                <v-card rounded elevation="0" v-if="this.correoSend === false">
                  <v-card-text>
                    <ValidationObserver ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="handleSubmit">
                        <v-row class="px-md-5">
                          <v-col cols="12">
                            <!-- EMAIL FIELD -->
                            <ValidationProvider
                              name="Correo electrónico"
                              rules="required|email"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                label="Correo electrónico"
                                v-model="user.email"
                                hint="Introduzca su correo"
                                autofocus
                                :disabled="disabled == 1"
                                :error-messages="errors[0]"
                              />
                            </ValidationProvider>
                          </v-col>
                        </v-row>

                        <v-row justify="center">
                          <v-btn
                            color="primary"
                            type="submit"
                            class="login-button"
                            :loading="loading"
                            :disabled="invalid"
                            >Restablecer</v-btn
                          >
                        </v-row>
                      </form>
                    </ValidationObserver>
                  </v-card-text>
                </v-card>
                <v-card rounded elevation="0" class="pa-6" v-else>
                  <v-card-text>
                    <v-row justify="center">
                      <span class="logo-container__text">
                        {{ message }}
                      </span>

                      <span class="logo-container__text_two">
                        En caso de no haberlo recibido.
                        <a v-on:click="handleSubmit()" class="mx-1"
                          >Haga click aquí
                        </a>
                        para enviarlo nuevamente
                      </span>
                    </v-row>
                    <v-row justify="center">
                      <RouterLink :to="{ name: 'Login' }" class="btn-default">
                        <span justify="center">
                          Iniciar Sesión
                        </span>
                      </RouterLink>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import UserService from '@/services/user'
export default {
  data() {
    return {
      disabled: 0,
      loading: false,
      correoSend: false,
      message: null,
      user: {
        email: ''
      }
    }
  },
  computed: {
    imgCard() {
      // return require('@/assets/logo-safe.png')
      return ''
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      this.correoSend = false
      this.disabled = 1
      try {
        let lang = navigator.language.substr(0, 2)
        var data = await UserService.sendEmailConfirmation(
          this.user.email,
          lang
        )
        this.correoSend = true
        this.message = data.message
      } catch (e) {
        this.$dialog.notify.error(e.message)
        this.user.email = ''
        this.correoSend = false
      } finally {
        this.loading = false
        this.disabled = 0
      }
    }
  }
}
</script>

<style>
#auth label.v-label {
  transform: translateY(-18px) scale(.75);
}
</style>
<style scoped>
.theme--light.v-application.bg-login {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
  background: rgba(8, 48, 84, 1);
  background-size: cover !important;
  background-repeat: no-repeat;
  /* font-family: Lato; */
}
.login-container {
  min-height: 100vh;
}
.login-container__row {
  min-height: 100vh;
}
.login-container__col {
  min-height: 100vh;
}
.left {
  /* background-color: rgb(1, 1, 75); */
  /* background: url('../../assets/AV_001_inicio.png') no-repeat center/100% !important; */
  background-image: url('../../assets/AV_001_inicio_sinletras.jpg');
  /* background-position: center center; */
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  background-size: cover;
  background-color: rgb(1, 1, 75);

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  height: 100vh;
}
.left__text {
  /* width: 350px; */

  text-align: left;
  font: normal normal 300 44px/52px Lato !important;
  letter-spacing: -1.1px !important;
  color: #0294de !important;
  opacity: 1;
}
.right {
  display: flex;
  align-items: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}
.test {
  background-color: red;
}
.form {
  display: flex;
  flex-direction: column;
}
.links {
  display: flex;
  justify-content: space-between;
}
.links a {
  font: normal normal normal 16px/27px Lato;
  letter-spacing: 0px;
  color: #00559e;
  opacity: 1;
  text-decoration: none;
}
.login-button {
  width: 208px;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}
.logo-container__text {
  margin-top: 20px;
  text-align: center;
  line-height: 1.5 !important;
  font: normal normal normal 18px/14px Lato;
  letter-spacing: 0px;
  color: #8990ad;
  opacity: 1;
}

.logo-container__text_two {
  text-align: center;
  line-height: 1.5 !important;
  font: normal normal normal 18px/14px Lato;
  letter-spacing: 0px;
  color: #8990ad;
  opacity: 1;
}
.logo-container__image {
  width: 90%;
}
.bg-login {
  font: normal normal normal 16px/27px Lato;
}
.form label {
  font: normal normal normal 12px/15px Lato;
  letter-spacing: 0px;
}
.text-sm {
  font-size: 15px;
}

.btn-default {
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  padding: 10px;
  opacity: 1;
  text-decoration: none;
}

.btn-default > span {
  text-align: center;
  font: normal normal normal 16px/19px Lato;
  letter-spacing: -0.4px;
  padding: 18px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}
.uppercase {
  text-transform: uppercase;
}

@media (max-width: 979px) {
  .logo-container__text {
    font: normal normal normal 16px/18px Lato;
  }
  .logo-container__text_two {
    font: normal normal normal 16px/18px Lato;
  }
  .text-left {
    font: normal normal normal 12px/27px Lato;
  }
}
</style>
